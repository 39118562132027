import React, { useEffect, useState, useRef } from "react";
import "./Themeaudio.scss";
import Lottie from 'react-lottie';
import themeSong from "../../assets/audio/Ice-on-my-wrists.mp3";
import ThemeSongLottie from "../../assets/LottieAnimations=/lf30_editor_dd1izdfp.json";
const Themeaudio = ({ ...props }) => {
   
   
    // const [speed, setSpeed] = useState(0)
    // const playAnimation = () => {
    //     setSpeed(1)
    // }
    // const pauseAnimation = () => {
    //     setSpeed(0)
    // }





    const audioEle = useRef();
    // const [isPlay, setIsPlay] = useState(false);
    // console.log(audioEle)
    // useEffect(() => {
    //     if (isPlay) {
    //         audioEle.current.play()
    //      } else {
    //         audioEle.current.pause()
    //      }
    // }, [isPlay])
    // useEffect(() => {
    //     audioEle.current.pause()
    //     setIsPlay(true)
    // }, [props?.setShowVideo || props?.showVideoTwo])
    // const playVideoHandler = () => {
    //     setIsPlay(prevPlay => !prevPlay);
    // }
    // const onLoadedMetadata = () => {
    //     setIsPlay(prevPlay => !prevPlay)
    //     audioEle.current.pause()
    // };
    const LightOption = {
        loop: true,
        // autoplay: true,
        animationData: ThemeSongLottie,
        className: "lottie",
        rendererSettings: {
            preserveAspectRatio: "xMidYMid none",
            viewBox: "0 0 140 1400",
        },
    };
    return (
        <>
            <section className="audioFile">
                <div className="playAudio">
                    {/* <span className="autoText">{!isPlay ? 'Play' : 'Pause'}</span> */}
                    {/* <audio controls playsInline ref={audioEle} onEnded={onLoadedMetadata}> */}
                    <audio controls playsInline autoPlay>
                        {/* <source src="horse.ogg" type="audio/ogg" /> */}
                        <source src={themeSong} type="audio/mpeg" />
                    </audio>
                    {/* <button onClick={playVideoHandler} className={!isPlay ? 'Play' : 'Pause'}> */}
                    <button >
                        {/* <span className="playing__bar playing__bar1"></span>
                        <span className="playing__bar playing__bar2"></span>
                        <span className="playing__bar playing__bar3"></span>
                        <span className="playing__bar playing__bar2"></span>
                        <span className="playing__bar playing__bar1"></span>
                        <span className="playing__bar playing__bar2"></span>
                        <span className="playing__bar playing__bar3"></span>
                        <span className="playing__bar playing__bar2"></span>
                        <span className="playing__bar playing__bar1"></span>
                        <span className="playing__bar playing__bar2"></span>
                        <span className="playing__bar playing__bar3"></span> */}
                        <Lottie options={LightOption} width={200} />
                        {/* <Lottie options={LightOption} width={200} speed={speed} /> */}
                    </button>
                </div>
            </section>
        </>
    )
}
export default Themeaudio