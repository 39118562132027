import React from 'react';
import './CommonBtn.scss';

const CommonBtn = (props) => {
  return (
    <>
    <button className={`commonBtn ${props.className}`} onClick={props.onClick}><span>{props.title}</span></button>
    </>
  )
}

export default CommonBtn