import React from "react";
import { Row, Modal } from "react-bootstrap";
import "./ConnectWallet.scss";
import One from '../../assets/images/layer1.png';

const ConnectWallet = (props) => {
  return (
    <Modal
      scrollable={true}
      className="connect_wallet"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal title</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="inner">
          <img src={One} alt="" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConnectWallet;
