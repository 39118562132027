import React from 'react';
import './Timer.scss'
// import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
const Timer = () => {
    // Random component
    const Completionist = () => <span>You are good to go!</span>;
    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <>
                <span className="elseText">
                    <Completionist />
                </span>
            </>;
        } else {
            // Render a countdown
            return <span>{days} : {hours} : {minutes} : {seconds}</span>;
        }
    };
    return (
        <>
            <div className="timerHandle">
                <Countdown
                    date={Date.now() + 859555000}
                    renderer={renderer}
                    daysInHours={true}
                />
            </div>
        </>
    )
}
export default Timer