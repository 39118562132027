import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { Nav, Navbar } from "react-bootstrap";
// import { NavLink } from "react-router-dom";
// import Logo from "../../assets/images/octox-logo.png";
import "./HeaderStyle.scss";
import LoGo from '../../assets/images/logo.svg';
import CommonBtn from "../ui/commonBtn/CommonBtn";
const Header = () => {
  return (
    <header>
      <Container>
        <Row>
          <Col>
            <Link to="/">
              <img src={LoGo} alt="Logo" />
            </Link>
          </Col>
          <Col>
            <div className="text-end">
              <CommonBtn title="Launch App" />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
export default Header;
