import React from "react";
import "./MainLayoutStyle.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import { Outlet } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const MainLayout = () => {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [navigate]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default MainLayout;
