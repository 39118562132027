import React from "react";
import { Link } from "react-router-dom";
import "./FooterStyle.scss";

const Footer = () => {
  return(
    <>
    <footer className="footer">
      <div className="container">
        <ul>
          <li>© 2022 UNGA. All Rights Reserved.</li>
          <li><Link to="#">Products</Link></li>
          <li><Link to="#">Overview</Link></li>
          <li><Link to="#">Docs</Link></li>
          <li><Link to="#">About</Link></li>
        </ul>
      </div>
    </footer>
    </>
  )
};

export default Footer;
