import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import playcard1 from "../../assets/images/ThumbOne.png";
import playcard2 from "../../assets/images/ThumbTwo.png";
import playcard3 from "../../assets/images/ThumbThree.png";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";
import "./HomeStyle.scss";
import Comming from '../../assets/images/text.svg';
import CommonBtn from "../../components/ui/commonBtn/CommonBtn";
import Timer from "../../components/ui/timer/Timer";
import Themeaudio from "../../components/themeaudio/Themeaudio";
const Home = () => {
  const cardImg = { one: playcard1, two: playcard2, three: playcard3 };
  const index = { 0: "one", 1: "two", 2: "three" };
  const [cards, setCards] = useState(["one", "two", "three"]);
  const shuffle = (e) => {
    if (e === 0) {
      handleShow(true);
    } else {
      const clone = cards.slice();
      clone.splice(e, 1);
      clone.unshift(cards[e]);
      setCards(clone);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="about_sec">
        <Container>
          <div className="about_sec_inner">
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="left">
                  <h1>
                    <span>AGMÜ300X HOLDINGS </span>
                    LAUNCHING
                  </h1>
                  <h3>All-In-One Financial Instrument Trading </h3>
                  <p>For the high end asset backed by sports luxury real estate ambassadors</p>
                  <CommonBtn title="Launch App" className="big" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="right">
                  <div className="card__box">
                    {cards.map((e, i) => {
                      return (
                        <div
                          key={i}
                          className={`card__box__pic play_${index[i]}`}
                          onClick={() => shuffle(i)}
                        >
                          <img src={cardImg[e]} alt="icon" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="comming_soon">
            <div className="comming_soon_inner">
              <img src={Comming} alt="" />
              <Timer/>
              <p>Join now today reserve your U300x token now which will allow you endless returns assets and a life changing investment opportunity</p>
            </div>
          </div>
        </Container>
      </section>
      <ConnectWallet
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
      />
      <Themeaudio/>
    </>
  );
};
export default Home;
